//button#quantityminus.btn.btn-primary(type="submit") -
//input#quantity(type="number"  value="1" )
//button#quantityplus.btn.btn-primary(type="submit") +

var quantityNumber = parseInt($('#quantity').val())

$('#quantityplus').on('click', function(event) {
  quantityNumber = quantityNumber + 1;
  $('#quantity').val(quantityNumber)
  /* Act on the event */
});

$('#quantityminus').on('click', function(event) {
  if(quantityNumber==1){
    quantityNumber=1;

  }else{
    quantityNumber = quantityNumber - 1;
  }
  $('#quantity').val(quantityNumber)
});
